import {gtag} from "Utilities.js"
import './consentmanager.css';
import {useState} from 'react';

export default function ConsentManager() {
	const [hovered, setHovered] = useState(false);
	const [authorizedConsent, setAuthorizedConsent] = useState(!!localStorage.getItem('authorizedConsent'));

	const toggleHover = () => setHovered(!hovered);
	const setConsent = (consentObj)=>{
		localStorage.setItem('authorizedConsent', 'true');
		localStorage.setItem('consentDetails', JSON.stringify(consentObj));
		gtag('consent', 'update', consentObj);
		setAuthorizedConsent(true);
	}
	function allowAnalytics(){
		setConsent({
			'analytics_storage': 'granted'
		,	'functionality_storage': 'granted'
		, 'security_storage': 'granted'
		});
	}
	function denyAnalytics(){
		setConsent({
			'analytics_storage': 'denied'
		,	'functionality_storage': 'denied'
		, 'security_storage': 'denied'
		});
	}



	return (
		<section className={authorizedConsent === false ? 'consent' : 'hidden'}>
			<p>
				Hey. We don't use cookies but we DO use Google Analytics to make sure our site works correctly.
			</p>

			<p>
				Can we use your analytics data to keep our site running smoothly? No pressure.
			</p>
			<div className="consent-choice">
				<button className="button small-button green" onClick={allowAnalytics} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>Yes{hovered ? ", you can use my analytics data but don't sell or share any of my other data with anyone." : null}</button>
				<button className="button small-button red" onClick={denyAnalytics}>Nope.</button>
			</div>
		</section>

	)
}