import "styles/variables.css";
import "styles/base.css";

import {Header} from 'routes/partials/Header.js';
import {Main} from 'routes/home/Main.js';
import {Footer} from 'routes/partials/Footer.js';
import Events from 'routes/events/Events.js';
import {EventDetail, EventDetailLoader} from 'routes/event-detail/EventDetail.js';
import {Contact} from 'routes/contact/Contact.js';
import {ErrorPage} from 'routes/error/ErrorPage.js';
import {About} from 'routes/about/About.js';
import {NahBro} from 'routes/nahbro/NahBro.js';
import ConsentManager from 'routes/partials/ConsentManager.js';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import {gtag} from "./Utilities.js";

const router = createBrowserRouter([{
	path: "/"
,	element: <Main />
,	errorElement: <ErrorPage />,
},{
	path: "events/"
,	element: <Events />
},{
	path: "event-detail/:eventName"
,	element: <EventDetail />
,	loader: EventDetailLoader
},{
	path: "contact/"
,	element: <Contact />
},{
	path: "about/"
,	element: <About />
},{
	path: "nahbro/"
,	element: <NahBro />
}

]);



function App() {
	const date = new Date();
  return (
    <>
			<a href="#main-content" className="sr-hidden">skip to content</a>
			<a href="#main-nav" className="sr-hidden">skip to navigation</a>
			<a href="#footer" className="sr-hidden">skip to footer</a>
			<Header />
			<div className="body-wrapper">
				<main id="main-content" className="main-content" role="main">
					<RouterProvider router={router} />
				</main>
				<div className="footer-placeholder"></div>
				<Footer />
				<p className="copywrite mobile-copywrite">super legit &copy;
					<span className="copywrite-year">{date.getFullYear()}</span>
				</p>
			</div>
			<ConsentManager />
		</>
  );
}

export default App;
