import "routes/home/main.css";
import "styles/about.css";

export const About = ()=>{
	return (
		<article className="content-item about-ci">
			<header className="ci-header about-header">
				<h1 className="ci-heading about-heading">what <span className="underline">is</span> super legit?</h1>
			</header>
			<figure className="ci-media about-media">
				<img src="/images/about/bg.png" alt="" />
			</figure>
			<div className="ci-description about-description">
				<p className="ci-text">Super Legit is an entertainment company. Part record label, event organizer, recording studio, publishing house, distribution company, and a whole lotta awesome.
				</p>
				<p className="ci-text">
					Our mission is to help good music find good people. We want to give ears to the resounding sound of the underground.
					Our goal is to throw shows that go bump in the night, in locations that inspire, with art by people who can't NOT make art.
				</p>
				<p className="ci-text">
					Inclusion and camaraderie are the vibe at our shows &mdash; whether you show up in a suit + tie or bunny ears + leotard. Whatever floats your boat (or sinks it for the insurance money).
					Whatever you're into that is peaceful, loving, and good is welcome.
				</p>
				<p className="ci-text">
					Enough reading! Come be a part of us. Bring who and what you love. Because you're Super Legit. I know it!
				</p>
				<p className="ci-text ci-text-author">-rü</p>
			</div>
		</article>
	);
}
