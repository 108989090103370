import "styles/events.css";
import {db} from "db.js";
import { useState } from "react";
import {getMonth, getDate, removeSpecialCharacters, toggle, whenIsEvent} from "Utilities.js";


function Location({location}){
	if(location.length === 2){
		let [address, link] = location;
		return (<p className="event-location"><a href={link} target="_blank" rel="noreferrer">{address}</a></p>)
	} else {
		return (<p className="event-location">{location[0]}</p>)
	}
}

function Event({event}){
	const [year, month, day] = getDate(event.date);
	const eventDetailHref = "/event-detail/" + removeSpecialCharacters(event.eventName);

	const [isEventOpen, setIisEventOpen] = useState(false);

	let eventItemDetailsClasses = "event-item-details ";
	let eventItemClasses = whenIsEvent(year,month,day) + " event-item ";
	if(isEventOpen){
		eventItemDetailsClasses += "open";
		eventItemClasses += "open"
	}

	function toggleIsOpen(){
		toggle(isEventOpen, setIisEventOpen);
	}
	function dontBubble(e){
		e.stopPropagation();
	}


	return (
		<article className={eventItemClasses} data-date={event.date} onClick={toggleIsOpen}>
			<header className="event-item-header">
				<h1 className="event-name">{event.eventName}</h1>
				<h2 className="event-date">{year} {getMonth(month)} {day}</h2>
			</header>
			<section className={eventItemDetailsClasses}>
				<p className="event-performers">{event.performers}</p>
				<Location location={event.location} />
				<p className="event-location">{event.time}</p>
				<a
				onClick={dontBubble}
				href={eventDetailHref}
				className="btn event-details-btn"
				target="_blank"
				rel="noreferrer">details</a>
			</section>
		</article>
	)

}

export default function Events(){
	return (
		<>
			{
				db.events.map((event, idx)=>{
					return (
					<Event event={event} key={idx}/>
				)})
			}
		</>
	)
}
