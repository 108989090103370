import "styles/events.css";

import {db} from "db.js";
import {removeSpecialCharacters, getMonth, whenIsEvent, getDate, Copy} from "Utilities.js";
import { useLoaderData } from "react-router-dom";
import YouTube from "YouTube.js";


// ()=>{}
function getData(eventName){
	return new Promise((resolve)=>{
		resolve(db.events.find((event)=> {
			console.log(removeSpecialCharacters(event.eventName), eventName);
			return removeSpecialCharacters(event.eventName) === eventName
		}))
	});
}

export async function EventDetailLoader({ params }) {
	// the getData function is where you'd make a call to the server to get data if you were going to do that.
	// we're using db here so whatever but when i implement firestore this is where we'll make the call- in
	// the getData function see:
			// https://gist.githubusercontent.com/ryanflorence/1e7f5d3344c0db4a8394292c157cd305/raw/f7ff21e9ae7ffd55bfaaaf320e09c6a08a8a6611/contacts.js
	// for example
  const eventData = await getData(params.eventName);

  return eventData;
}
//
// function Copy({copyArr}){
// 	return (
// 		<>
// 			{
// 				copyArr.map((_p, idx)=>{
// 					let p = converter.makeHtml(_p);
// 					return (
// 						<div dangerouslySetInnerHTML={{__html: p}} key={idx}></div>
// 					)
// 				})
// 			}
// 		</>
// 	)
// }


export const EventDetail = (props)=>{
	const event = useLoaderData();
	const {eventDetails} = event;
	console.log(event);
	const [year, month, day] = getDate(event.date);
	const isPast = whenIsEvent(year, month, day) === 'past';
	return (
		<>
		{(eventDetails.hasImages) ? (
			<picture className="ed-pic">
				<source
					className="img-sm ed-pic-source"
					srcSet={eventDetails.pictureSources[0]}
					media="(max-width: 480px)"
				/>
				<source
					className="img-lg ed-pic-source"
					srcSet={eventDetails.pictureSources[1]}
					media="(min-width: 481px) and (max-width:1024px)"
				/>
				<source
					className="img-dft ed-pic-source"
					srcSet={eventDetails.pictureSources[2]}
					media="(min-width:1025px)"
				/>
				<img
					className="img-dft ed-pic-source"
					srcSet={eventDetails.pictureSources[2]}
					alt={eventDetails.imgAlt}
				/>
			</picture>
		): null
		}
		{(eventDetails.hasYoutube) ? (
			<YouTube description={event.eventName} embedLink={eventDetails.embedLink} />
		): null}

			<div className="ed-item">
				<header className="event-detail-header">
					<h1 className="event-name ed-name">{event.eventName}</h1>
					<h2 className="event-performers ed-performers">{event.performers[0]}</h2>
					<h3 className="event-date ed-date">{year} {getMonth(month)} {day}</h3>
					<h4 className="event-location ed-location">{(isPast) ? 'was at ': ''} <a href={event.location[1]} target="_blank" rel="noreferrer"> {event.location[0]}</a></h4>
					<h5 className="event-location ed-time">{event.time}</h5>
				</header>
				<div className="ed-description-wrapper">
					{
						isPast ? (
							<Copy copyArr={eventDetails.pastCopy} />
						) : (
							<Copy copyArr={eventDetails.futureCopy} />
						)
					}
				</div>
			</div>
		</>
	)

}
