import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.js';
import reportWebVitals from './reportWebVitals.js';
import {gtag} from "./Utilities.js";

window.dataLayer = window.dataLayer || [];

gtag('consent', 'default', {
  'ad_storage': 'denied',
  'ad_user_data': 'denied',
  'ad_personalization': 'denied',
  'analytics_storage': 'denied'
});

gtag('js', new Date());
gtag('config', 'G-WVP7Y6WQB4');
// eslint no-extra-boolean-cast 0
if(!!localStorage.getItem('authorizedConsent')){
  let consentDetails = JSON.parse(localStorage.getItem('consentDetails'));
  console.log(consentDetails);
  console.log('diff? ', localStorage.getItem('consentDetails'))

  gtag('consent', 'update', consentDetails);
}

const root = ReactDOM.createRoot(document.getElementById('react'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


reportWebVitals();
