import {useEffect} from 'react';
import "styles/mostly-empty-page.css";

export const ErrorPage = ()=>{
	useEffect(()=>{
		setTimeout(()=> window.location.href="/", 30000);
	});

	let mailtolink = "mailto:contact@superlegit.fm?subject=Something is messed up on the website and it keeps happening and I am not happy about it";
	mailtolink = mailtolink.replace(/\s/ig, '%20')

	return(
		<div className="mostly-empty-page">
			<h1>Oh no! 404 Not Found</h1>
			<p>Either somebody messed up or you're doing some funky stuff. Either way, go <a href="/">home!</a>.</p>
			<p>If it keeps happening or you're upset enough to write us an email, write us an <a href={mailtolink} target="_blank">email</a></p>
			<p>This message will self-destruct in 30 seconds</p>
		</div>
	)
}
