import "./footer.css";
import { useState } from "react";

import {LISTENS, SOCIALS, SITES} from 'Constants.js';
import {NavItem} from './NavItem.js';


const FooterSection = ({sectionName, objToIterateOver}) => {

return (

	<section className="footer-details">
		<h1 className="footer-details-heading">{sectionName}</h1>
		<ul id="footerlisten" className="footer-details-list">

		{
			Object.entries(objToIterateOver).map((arr, key)=>{
				const [text, href] = arr;
				return (
					<NavItem
					href={href}
					text={text}
					liClasses="footer-list-item"
					aClasses="footer-list-item-link"
					shouldOpenNewWindow={true}
					key={key}/>
				)
			})
		}
		</ul>
	</section>
	)
}



export const Footer = ()=>{
	const date = new Date();
	const [isFooterOpen, setIsFooterOpen] = useState(false);
	let footerClasses = "footer ";
	let footerDetailsWrapperClasses= "footer-details-wrapper "

	if(isFooterOpen){
		footerClasses += 'details-open';
		footerDetailsWrapperClasses += 'open';
	}

	function toggleFooter(){
		if(isFooterOpen === true){
			setIsFooterOpen(false);
		} else {
			setIsFooterOpen(true);
		}
	}

  return (
			<footer id="footer" className={footerClasses}>
				<header id="footer-header" className="footer-header" role="button" aria-pressed="false" onClick={toggleFooter}>
					<h1>footer</h1>
					<div className="footer-expander">{isFooterOpen ? "-" : "+"}</div>
				</header>
				<div className={footerDetailsWrapperClasses}>
					<FooterSection sectionName="listen" objToIterateOver={LISTENS} />
					<FooterSection sectionName="socialize" objToIterateOver={SOCIALS} />
					<FooterSection sectionName="more stuff" objToIterateOver={SITES} />
					<div className="copywrite desktop-copywrite">
						<p>super legit</p>
						<p>super legit</p>
						<p>super legit</p>
						<p>super legit</p>
						<p>super legit</p>
						<p>super legit</p>
						<p>super legit</p>
						<p>&copy; <span className="copywrite-year">{date.getFullYear()}</span></p>
					</div>
				</div>
			</footer>
  );
}
