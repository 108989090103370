

export const db = {
	posts: [
		{
			heading: "Rü's Revolution Livestream"
		,	hasImages: true
		,	pictureSources: [
				'images/rudjing/revolution-livestream.jpeg'
			,	'images/rudjing/revolution-livestream.jpeg'
			,	'images/rudjing/revolution-livestream.jpeg'
		]
		,	imgAlt: "Rü djing in a storage unit on a stage that resembles the arm of a Gundum Wing but with TVs."
		,	caption: "New Livestream"
		, hasYoutube: false
		, hasDownload: true
		, downloadLink: "revolution_livestream.mp3"
		, hasFirebaseDownload: true
		, hasDescription: true
		, description: ["Here's a soundtrack for everyone but also specifically for those people looking around the world like... damn. stuff needs to change."]
		, hasAudio: true
		, audioContent: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1048964308&color=%2330bba5&inverse=false&auto_play=false&show_user=true"
		, hasSoundcloud: true
		, trackInformation: ["rü", "Revolution Livestream"]
		, hasTracklist: true
		, tracklist: [
			{
				artist: "Bob Dylan vs Tobias Lueke",
				trackName: "When The Ship Comes In vs Noise (rü's revolution edit)",
				buyLink: ["https://www.beatport.com/release/noise/2417011", "Noise on beatport"]
			},
			{
				artist: "Nas",
				trackName: "The Don (J Paul Getto Remix)",
				buyLink: ["#", "none :("]
			},
			{
				artist: "Eskuche",
				trackName: "Passion",
				buyLink: ["https://www.beatport.com/release/passion-ep/2703807", "Passion on beatport"]
			},
			{
				artist: "Harry Romero",
				trackName: "Revolution (Deep In Jersey Extended Mix)",
				buyLink: ["https://www.beatport.com/track/revolution/10549101", "Revolution on beatport"]
			},
			{
				artist: "Robosonic, Ferreck Dawn",
				trackName: "Old dollars",
				buyLink: ["https://www.beatport.com/release/old-dollars/2064248", "Old Dollars on beatport"]
			},
			{
				artist: "Will Clarke",
				trackName: "Mercy",
				buyLink: ["https://www.beatport.com/release/mercy-ep/2340098", "Mercy on beatport"]
			},
			{
				artist: "Ursula Rucker, Jay Hill",
				trackName: "Evolution Party (Tiger Stripes Remix)",
				buyLink: ["https://www.beatport.com/release/evolution-party/2916114", "Evolution Party on beatport"]
			},
			{
				artist: "Kinnerman",
				trackName: "Promised Land",
				buyLink: ["https://www.beatport.com/release/promised-land/2373039", "Promised Land on beatport"]
			},
			{
				artist: "Fatboy Slim",
				trackName: "Right Here, Right Now (CamelPhat Remix)",
				buyLink: ["https://www.beatport.com/release/right-here-right-now-camelphat-remix/2201962", "RHRN on beatport"]
			},
			{
				artist: "Billy Kenny",
				trackName: "Work",
				buyLink: ["https://www.beatport.com/track/work/10501883", "Work on beatport"]
			},
			{
				artist: "Obskür",
				trackName: "Bayside",
				buyLink: ["https://obskr.bandcamp.com/album/909s-and-garda-fines", "Promised Land on bandcamp"]
			},
			{
				artist: "Floorplan",
				trackName: "Save the Children",
				buyLink: ["https://www.beatport.com/track/save-the-children/14043859" , "Save the Children on beatport"]
			},
			{
				artist: "Sharam Jey, Andruss, Dewitt Sound",
				trackName: "Right Back",
				buyLink: ["https://www.beatport.com/track/right-back-feat-dewitt-sound/12278007", "Right Back on beatport"]
			},
			{
				artist: "Shdw, Obscure Shape",
				trackName: "Himmel Und Erde",
				buyLink: ["https://www.beatport.com/track/himmel-und-erde/9317848", "Himmel Und Erde on beatport"]
			},
			{
				artist: "Modeselektor, Tommy Cash",
				trackName: "Who",
				buyLink: ["https://www.beatport.com/track/who/11596209", "Who on beatport"]
			},
			{
				artist: "Jamie XX",
				trackName: "Gosh",
				buyLink: ["https://www.beatport.com/track/gosh/6720417", "Gosh on beatport"]
			},
			{
				artist: "Rü",
				trackName: "A Chill Song",
				buyLink: ["https://distrokid.com/hyperfollow/r40/a-chill-song-2", "A Chill Song on all the places"]
			}		]
		},
		{
			heading: "Ravelight Savings Time"
		,	hasImages: true
		, hasYoutube: false
		,	pictureSources: [
				'images/rst/insta.jpg'
			,	'images/rst/print.jpg'
			,	'images/rst/print.jpg'
		]
		,	imgAlt: "Ravelight Savings Time"
		,	caption: "event"
		, date: "21 Sep 2019"
		, location: "TBA Chicago"
		, hasDescription: false
		, hasEvent: true
		,	ticketLink: ["https://www.thebloxoffice.com/events/261", "tickets"]

	},
		{
		heading: "Rü's swanky new mixtape"
	,	hasImages: true
	,	pictureSources: [
			'images/rudjing/rudjing.jpg'
		,	'images/rudjing/rudjing.jpg'
		,	'images/rudjing/rudjing.jpg'
	]
	,	imgAlt: "Rü djing at A Super Legit Black Market"
	,	caption: "New Mix"
	, hasYoutube: false
	, hasDownload: true
	, downloadLink: "https://drive.google.com/drive/folders/1lJlcdqaGRcQVmXkpfDaRc3FryyRZb9Xc?usp=sharing"
	, hasFirebaseDownload: false
	, audioContent: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/621284937&color=%2330bba5&inverse=false&auto_play=false&show_user=true"
	, hasSoundcloud: true
	, hasAudio: true
	, hasDescription: true
	, description: ["rü just dropped his new mix for the summer!", "Tracklist available on his [soundcloud](https://soundcloud.com/rumusic/for-your-consideration). Like and subscribe and all that noise &lt;3 ."]
	, hasTracklist: false
	, trackInformation: ["rü", "For Your Consideration"]
},
		{
			heading: "A Super Legit Black Market Recap!"
		,	caption: "recap"
		,	hasImages: false
		, hasDownload: false
		, hasYoutube: true
		,	embedLink: "https://www.youtube-nocookie.com/embed/vQeBWzkxbGk"
		, hasDescription: true
		, description: ["We had a blast! Great job to everyone involved. Be on the lookout for our next event sometime in September or October!"]
		},
		{
			heading: "A Super Legit Black Market"
		,	caption: "event"
		,	hasImages: false
		, hasDownload: false
		, hasYoutube: true
		,	embedLink: "https://www.youtube-nocookie.com/embed/aheWD4YJFp4"
		, hasDescription: true
		, description: [""]
		, date: "13 Apr 2019"
		, location: "TBA Chicago"
		, hasEvent: true
		,	ticketLink: ["https://www.eventbrite.com/e/black-market-tickets-58460952282", "tickets"]

		}



	]
,	events: [
	{
		date: "2019, 3, 13"
	,	eventName: "Black Market"
	// it'd be cool to have each performer have their own page with some music on it.
	, performers: ["rü R34L doc pearson south of roosevelt"]
	,	location: ["at Secret Location, Chicago"]
	,	time: "8:00 pm - 2:00 am CST"
	, eventDetails: {
			hasImages: true
		,	pictureSources: [
				"/images/black_market/sm.png"
			, "/images/black_market/lg.png"
			, "/images/black_market/dft.png"
			]
		,	imgAlt: "A super epic flyer designed by the wonderful Kyle Stewart"
		, futureCopy: [
				"This particular Black Market is in celebration of the release of rü's Black / White EP and the launching of Super Legit Productions. It'll be great."
			,	"Tickets Include drinks"
			, "21 and older with ID to attend"
			, "[Tickets available here](https://www.eventbrite.com/e/black-market-tickets-58460952282)"
			]
		,	pastCopy: [
				"For the first event ever thrown by the Super Legit team, things went pretty well!"
			,	"The GoodBus crew came thru with full effect"
			, "[Haze](https://www.instagram.com/art.haze.exe/) put together an epic stage"
			, "And with the exception of some drama after the fact, it was all in all a great experience! Check the recap video!"
			]
		,	hasYoutube: true
		, embedLink: "https://www.youtube-nocookie.com/embed/vQeBWzkxbGk"
	}
}
,{
	date: "2019, 2, 5"
,	eventName: "Black Market Announce Video Shoot"
, performers: ["You and anyone you know who wants to come be in a super legit announce video."]
,	location: ["3401 N Knox Ave, Chicago", "https://goo.gl/maps/tjdXg1XkmJk"]
,	time: "7:00 pm - 9:30 pm CST"
, eventDetails:{
		hasImages: false
	, hasYoutube: true
	,	embedLink: "https://www.youtube-nocookie.com/embed/aheWD4YJFp4"
	, futureCopy: [
			"We need models for a weird movie we wanna make for our launch party! Come thru!"
		]
	,	pastCopy: [
			"We made an epic Radio-Soulwax-Inspired video for our launch party: Black Market!"
		, "Thanks to everyone who danced with us!"
		, "Shout out to [George Mulcahy](https://www.instagram.com/george_mulcahy/) for the video/editing work."
		]
	}
}
]


}
