import "styles/mostly-empty-page.css";
import "./contact.css";

import {SOCIALS} from "Constants.js";
import {fb} from 'Fb.js';
import {useState} from 'react';


const ContactForm = ()=>{
	const [errorMsg, setErrorMsg] = useState(null);
	const [hasErrorMsg, setHasErrorMsg] = useState(false);
	const [wasSuccess, setWasSuccess] = useState(false);

	async function submitForm(e){
		const data = new FormData(e.target);
		e.preventDefault();
		setHasErrorMsg(false);
		setErrorMsg(null)

		// grecaptcha.ready( async function() {
		// 	let token = await grecaptcha.execute('6LeyOuspAAAAACUcvlLNYCfXOZRcXRZizNaPbHfM', {action: 'submit'});//.then(function(token) {})
		// 	console.log('token', token);
			try{
				await fb.submitContactForm(data);
			} catch (e) {
				console.log(e)
				setHasErrorMsg(true);
				setErrorMsg(e.message);
				return;
			}
			setWasSuccess(true)
		// })
	}


	return (
		<>
			{wasSuccess === true ? (
				<p>Your message has been sent- We'll get back to you as soon as we can!</p>
			) : (
				<section className="contact-form">
					<h1>Contact Us</h1>
					<form onSubmit={submitForm}>
						 <label><span className="sr-hidden">Your Name</span>
							 <input placeholder="YOUR NAME" type="text" name="name" required maxLength="200"/>
						 </label>
						 <label><span className="sr-hidden">Your email</span>
							 <input placeholder="YOUR EMAIL" type="email" name="email" required maxLength="320"/>
						 </label>
						 <label><span className="sr-hidden">Your Message</span>
							 <textarea rows="5" placeholder="YOUR MESSAGE TO US (please be kind)" name="message" spellCheck="true"
												 required maxLength="6000"></textarea>
						 </label>

						 <button type="submit">submit</button>
						 {hasErrorMsg ? (
							 <p>{errorMsg}</p>
						 ) : null}
					 </form>
				</section>
			 )}
		</>
	)



}




export const Contact = ()=>{
	let socials = Object.entries(SOCIALS);
	return (
		<div className="mostly-empty-page">
			<ContactForm />
			{/*<h1 className="contact-us-email"><a href="mailto:support@superlegit.fm" target="_blank">email us</a></h1>*/}
			{/*<p className="contact-yell">or yell at us on {*/}
			{/*	socials.filter((social)=>social[0]!=='contact').map((social, idx)=>{*/}
			{/*		let [name, href] = social;*/}
			{/*		return (*/}
			{/*			<a href={href} target="_blank" rel="noreferrer" key={idx}>{name }{*/}
			{/*				(idx===socials.length -3) ? ', or ' : ', '*/}
			{/*			}</a>*/}
			{/*		)*/}
			{/*	})*/}
			{/*}</p>*/}
		</div>
	)


}
