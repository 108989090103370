import {initializeApp, getApp} from "firebase/app";
import {getDatabase, set, push, connectDatabaseEmulator, ref as dbRef} from "firebase/database";
import {getStorage, ref, getDownloadURL} from "firebase/storage";
import {getAnalytics, isSupported} from "firebase/analytics";
import {getToken, initializeAppCheck, ReCaptchaV3Provider} from "firebase/app-check";



const firebaseConfig = {
	apiKey: "AIzaSyB4GmBoQ5pQCw1bjYiIbFM7tSYMl00VofM",
	authDomain: "superlegit-8c67d.firebaseapp.com",
	databaseURL: "https://superlegit-8c67d.firebaseio.com",
	projectId: "superlegit-8c67d",
	storageBucket: "superlegit-8c67d.appspot.com",
	messagingSenderId: "632775529489",
	appId: "1:632775529489:web:f0989e2b7be55b5d534db0",
	measurementId: "G-WVP7Y6WQB4",
	recaptchaSiteKey: "6LeyOuspAAAAACUcvlLNYCfXOZRcXRZizNaPbHfM"
};

class Firebase {
	constructor () {
		const _this = this;
		Object.assign(this, firebaseConfig);
		initializeApp(firebaseConfig);
		this.appCheck = initializeAppCheck(this.app, {
			provider: new ReCaptchaV3Provider(_this.recaptchaSiteKey)
		, isTokenAutoRefreshEnabled: true
		});

		this.app = getApp();
		this.storage = getStorage(this.app);
		isSupported().then((result)=>{
			if(result === true){
				_this.analytics = getAnalytics(this.app);
			}
		});

		this.db = getDatabase(this.app);

		if (location.hostname === "localhost") {
			connectDatabaseEmulator(this.db, "127.0.0.1", 9000);
		}
		this.contactFormPath = 'contactMessage';
	}

	async getDownloadUrl (nameInStorageBucket) {
		const storageRef = ref(this.storage, nameInStorageBucket);
		return getDownloadURL(storageRef);
	}

	async submitContactForm (contactForm) {
		let msgRef = dbRef(this.db, this.contactFormPath);
		let newMsgRef = push(msgRef);

		let appCheckTokenResponse;

		try{
			appCheckTokenResponse= await getToken(this.appCheck);
		} catch(e){
			console.error(e);
			return;
		}

		return set(newMsgRef, {
			name: contactForm.get('name')
		, email: contactForm.get('email')
		,	message: contactForm.get('message')
		,	appCheckToken: appCheckTokenResponse.token
		});
	}


}

const fb = new Firebase();

export {fb};
