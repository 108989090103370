
export const SOCIALS = {
	twitter: 'https://twitter.com/superlegit_fm/'
,	facebook: 'https://www.facebook.com/superlegitfm/'
,	instagram: 'https://www.instagram.com/superlegit_fm/'
,	contact: '/contact'
};

export const LISTENS = {
	soundcloud: 'https://soundcloud.com/superlegit_fm'
,	youtube: 'https://www.youtube.com/channel/UCN76VM7O1k2uziyPAMkpiGw'
,	spotify: 'https://open.spotify.com/artist/19Na5NJ0JY0fzUFj7wEPTr?si=q5u45VbfRD6pHNNLM1BX_w'
,	bandcamp: 'https://superlegitrecords.bandcamp.com/'
};

export const SITES = {
	label: 'https://superlegit.fm'
,	store: LISTENS.bandcamp
};

export const MAINNAVSECTIONS = {
	home: '/'
,	events: '/events'
,	store: SITES.store
,	contact: SOCIALS.contact
,	about: '/about'
};
