import showdown from "showdown";
const converter = new showdown.Converter();


export const removeSpecialCharacters = (stringToReplace)=> stringToReplace.replace(/[^\w]/gi, '');


export const getMonth = (month)=>{
	const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	return months[month];
}


export const toggle = (isStateBoolean, setStateFunction)=>{
	if(isStateBoolean === true){
		setStateFunction(false);
	} else {
		setStateFunction(true);
	}
}

export const getDate = (dateArr) => dateArr.split(',').map((str)=> parseInt(str.trim(),10));

export const whenIsEvent = (eventYear, eventMonth, eventDay) => {
	const now = new Date(Date.now());
	const [currentYear, currentMonth, currentDay] = [now.getFullYear(), now.getMonth(), now.getDate()];
	if(currentYear === eventYear && currentMonth === eventMonth && currentDay === eventDay){
		return 'today';
	}
	if(
		(eventYear > currentYear) ||
		(eventYear === currentYear &&  eventMonth > currentMonth) ||
		(eventYear === currentYear &&  eventMonth === currentMonth && eventDay > currentDay)
	){
		return 'future';
	}
	return 'past';
}



export function Copy({copyArr}){
	return (
		<>
			{
				copyArr.map((_p, idx)=>{
					let p = converter.makeHtml(_p);
					return (
						<div dangerouslySetInnerHTML={{__html: p}} key={idx}></div>
					)
				})
			}
		</>
	)
}



export function gtag(){ window.dataLayer.push(arguments); }
