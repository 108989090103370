import {removeSpecialCharacters} from "Utilities.js";
import SC from './SC.js';

class AudioPlayer{
	constructor(button, timeline, audioElement, playHead, timeRemaining){
		this.button = button;
		this.timeline = timeline;
		this.audioElement = audioElement || document.querySelector(`#${button.dataset.audioselector}`);
		this.playHead = playHead || document.querySelector(`#${button.dataset.audioselector}-playhead`);
		this.timeRemaining = timeRemaining || document.querySelector(`#${button.dataset.audioselector}-timeremaining`);
		this.playingAnimationListener = () => requestAnimationFrame(()=>{
			// could be a bug if undfined. i dont remember how initialization works.
			this.animatePlayback();
		});

		this.addClickListener();
		this.addTimelineListener();
	}

	getAudioProperties(){
		return Promise.all([this.audioElement.currentTime, this.audioElement.duration, !!this.audioElement.paused]);
	}

	animatePlayback(){
		this.getAudioProperties().then(properties=>{
			let currentTime = properties[0]
				, duration = properties[1]
				, isPaused = properties[2];

			if(!isPaused){
				let completed = Math.round((currentTime / duration) * 1000) / 10;

				let secondsTotalRemaining = (duration - currentTime) / 1000;
				let minutesTotalRemaining = secondsTotalRemaining / 60;

				let minutesRemaining = Math.floor(minutesTotalRemaining);
				let secondsRemaining = Math.floor(secondsTotalRemaining % 60);
				this.playHead.style.width = `${completed}%`;
				this.timeRemaining.innerText = `-${minutesRemaining}:${secondsRemaining < 10 ? '0' + secondsRemaining : secondsRemaining}`;
			}
		}).catch((e)=> console.log(e));
	}

	play(){
		this.audioElement.play();
	}
	pause(){
		this.audioElement.pause();
	}

	togglePlay(toggleON){
		this.getAudioProperties().then((properties)=>{
			let isPaused = properties[2];

			if(isPaused || toggleON === true) {
				this.button.classList.remove('play-icon');
				this.button.classList.add('pause-icon');
				this.play();
				this.toggleAudioUpdateListener(true);
				// this.audioElement.addEventListener('timeupdate', this.playingAnimationListener)
			} else {
				this.button.classList.remove('pause-icon');
				this.button.classList.add('play-icon');
				this.pause();
				this.toggleAudioUpdateListener();
			}
		})
	}

	toggleAudioUpdateListener(shouldAdd){
		if(shouldAdd){
			this.audioElement.addEventListener('timeupdate', this.playingAnimationListener);
		} else {
			this.audioElement.removeEventListener('timeupdate', this.playingAnimationListener);
		}
	}

	addClickListener(){
		let that = this;
		this.button.addEventListener('click', that.togglePlay.bind(that));
	}

	getDuration(){
		return this.audioElement.duration;
	}

	getCurrentTime(){
		return this.audioElement.currentTime;
	}

	setCurrentTime(time){
		this.audioElement.currentTime = time;
	}

	addTimelineListener(){
		this.timeline.addEventListener('click', (e) => {
			let rect = this.timeline.getBoundingClientRect();
			let clickX = e.clientX;
			let percentage = Math.round(((clickX - rect.left) / (rect.right - rect.left)) * 100) / 100	;

			this.playHead.style.width = `${percentage * 100}%`;

			this.getAudioProperties().then((properties)=>{
				let duration = properties[1];
				this.setCurrentTime(Math.round(duration * percentage));
				this.togglePlay(true);
			});
		})
	}
}

class SoundCloudPlayer extends AudioPlayer{
	constructor(button, timeline, audioElement, playHead, timeRemaining){
		super(button, timeline, audioElement, playHead, timeRemaining, true);
		this.widget = SC.Widget(this.audioElement);
	}

	getAudioProperties(){
		let currentTime = new Promise(resolve=> this.widget.getPosition(position => resolve(position)))
		,	duration = new Promise(resolve=> this.widget.getDuration(currentDuration => resolve(currentDuration)))
		,	isPaused = new Promise(resolve=> this.widget.isPaused(isCurrentlyPaused => resolve(isCurrentlyPaused)));
		return Promise.all([currentTime, duration, isPaused]);
	}

	play(){
		this.widget.play();
	}

	pause(){
		this.widget.pause();
	}
	setCurrentTime(time){
		this.widget.seekTo(time);
	}

	toggleAudioUpdateListener(shouldAdd){
		if(shouldAdd){
			this.widget.bind('playProgress', this.playingAnimationListener);
		} else {
			this.widget.unbind('unbind');
		}

	}
}

document.addEventListener('DOMContentLoaded', ()=>{
	setTimeout(function(){


	let audioPlayers = document.querySelectorAll('.audioplayer-wrapper');
	audioPlayers.forEach(audioPlayer=>{
		if(audioPlayer.classList.contains('soundcloud')){
			new SoundCloudPlayer(
				audioPlayer.querySelector('.ap-play-btn'),
				audioPlayer.querySelector('.ap-timeline'),
				audioPlayer.querySelector('iframe')
				);
		} else {
			new SoundPlayer(audioPlayer.querySelector('.ap-play-btn'), audioPlayer.querySelector('.ap-timeline'));
		}
	});
},1000)
});




function SoundSource({hasSoundcloud, id, audioContentUrl, title}){
	const _id = (hasSoundcloud) ? `${id}-iframe`: `${id}-source`;
	return (hasSoundcloud) ? (
		<iframe id={_id}
		title={title}
		width="100%"
		height="140"
		scrolling="no"
		frameBorder="no"
		allow="autoplay"
		src={audioContentUrl}></iframe>
	) : (
		<source id={_id} srcSet={audioContentUrl} />
	)
}



export function SoundPlayer({trackInformation, audioContentUrl, hasSoundcloud}){
	const [artist, title] = trackInformation;
	const id = removeSpecialCharacters(title);
	const playhead = `${id}-playhead`
	return (
		<div className="ci-audio-wrapper">
			<div className="audioplayer-wrapper soundcloud">
				<div className="ci-audio" id={id}>
					<SoundSource hasSoundcloud={hasSoundcloud} id={id} audioContentUrl={audioContentUrl} title={title}/>
				</div>
				<button className="ap-play-btn play-icon" data-audioselector={id}></button>
				<div className="ap-timeline" data-audioselector={id}>
					<span className="ap-playhead" id={playhead}></span>
					<span className="ap-text ap-artist">{artist}</span>
					<span className="ap-text ap-trackname">{title}</span>
					<span id={`${id}-timeremaining`} className="ap-text ap-timeremaining">hit play</span>
				</div>
			</div>
		</div>
	);
}
