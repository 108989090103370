import "./nav.css";

import {NavItem} from './NavItem.js';
import {MAINNAVSECTIONS} from "Constants.js";
import { useState } from "react";
import {throttle} from "libs/lodash-throttle.js";


function initScroll(){
	let latestY = 0, prevY = 0, collapsed = false;
	const headernav = document.querySelector('#headernav');
	const hamburger = document.querySelector(".hamburger");
	const navList = document.querySelector(".nav-list");

	function showFullNav(){
		headernav.classList.remove('collapse');
		collapsed = false;
		hamburger.disabled = false;
	}

	function hideNav(){
		if(latestY > 50 || (latestY === 0 && window.scrollY < 50)){
			headernav.classList.add('collapse');
			collapsed = true;
			hamburger.disabled = true;
		}
	}

	function update(){
		let navClassList = navList.classList;
		let isCollapsed = navClassList.contains('open');

		if(latestY > 50){
			// if you're scrolling up and its collapsed, show
			if(prevY > latestY && collapsed === true){
				showFullNav();
				// if its not collapsed and you're scrolling down, hide
			} else if(collapsed === false && prevY < latestY && !isCollapsed){
					hideNav();
			}
		} else {
			// if it's within 50px of the top and its collapsed, show
			if(collapsed === true){
				showFullNav();
			}
		}

		prevY = window.scrollY;
	}

	function onScroll(e){
		latestY = window.scrollY;
		requestAnimationFrame(update);
	}

	window.addEventListener('scroll', throttle(onScroll, 300), false);
	//
	// document.addEventListener('showFullNav', showFullNav);
	// document.addEventListener('hideNav', hideNav);

}


// should probably use useEffect or something
setTimeout(initScroll, 1000);



export const Header = ()=>{
	const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
	// const [isHeaderScrollCollapsed, setIsHeaderScrollCollapsed] = useState(false);
	let hamburgerClasses = "hamburger btn ";
	let navListClasses = "nav-list ";

	if(isHamburgerOpen){
		hamburgerClasses += "open";
		navListClasses += "open";
	}

	function toggleNav(){
		if(isHamburgerOpen === true){
			setIsHamburgerOpen(false);
		} else {
			setIsHamburgerOpen(true);
		}
	}


  return (
		<header id="headernav" className="header-nav">
			<div className="helper-arrow"></div>
			<nav id="main-nav" className="nav-wrapper">
				<a href="/" className="img-nav-link">
					<span className="sr-hidden">Home</span>
					<img className="img-nav logo" src="/images/gus.svg" alt="" />
					<img className="img-nav wordmark vertical-wordmark" src="/images/wordmark.svg" alt=""/>
					<img className="img-nav wordmark horizontal-wordmark" src="/images/horizontal-wordmark.svg" alt=""/>
				</a>
				<div className="subnav-wrapper">
					<button className={hamburgerClasses} onClick={toggleNav}>
						<span className="hamburger-sr-hidden">{isHamburgerOpen ? 'Close Nav' : 'Open Nav'}</span>
					</button>
					<ul id="mainnavlist" className={navListClasses}>
					{
						Object.entries(MAINNAVSECTIONS).map((arr, key)=>{
							const [text, href] = arr;
							return (
								<NavItem href={href}
									text={text}
									liClasses="nav-item"
									aClasses="nav-item-link"
									key={key}/>
							)
						})
					}
						<li className="nav-item header-cart">
							<button className="btn cart header-cart-btn" data-cartcounter="true">
								<span className="cart-count"></span>
							</button>
						</li>
					</ul>
				</div>
			</nav>
			<div className="nav-notification">
				<img src="../images/icons/gus.png" alt="a super legit notification" className="gus-notification" />
				<p className="nav-notification-text" aria-live="assertive"></p>
			</div>
		</header>
  );
}
