import "./main.css";
import {db} from "db.js";
import {SoundPlayer} from "./SoundPlayer.js";
import {removeSpecialCharacters, Copy} from "Utilities.js";
import YouTube from "YouTube.js";
import {fb} from 'Fb.js';
import { useState, useEffect } from 'react';


function Track({artist, trackName, buylink}){
	const [href, title] = buylink;
	return (
		<tr>
			<td>{artist}</td>
			<td>{trackName}</td>
			<td><a href={href}>{title}</a></td>
		</tr>
	)
}

function Tracklist({tracklist}){
	return (
		<section className="tracklist">
			<header className="ci-header">
				<h1 className="ci-heading">Tracklist</h1>
				<table>
					<thead>
						<tr>
							<th>Artist</th>
							<th>Song</th>
							<th>Buy Link</th>
						</tr>
					</thead>
					<tbody>
						{tracklist.map((track, idx)=>{
							return (
								<Track
								artist={track.artist}
								trackName={track.trackName}
								buylink={track.buyLink}
								key={idx}
								/>)
						})}
					</tbody>
				</table>
			</header>
		</section>
	);
}

function Event({date, location, ticketLink}){
	const [href, text] = ticketLink;
	return (
		<div className="ci-description">
			<p className="ci-date">{date}</p>
			<p className="ci-date">{location}</p>
			<p className="ci-date"><a href={href} target="_blank" rel="noreferrer">{text}</a></p>
		</div>
	)
}

function Description({post, id}){
	const downloadid = `${id}link`;

	const [linkRef, setLinkRef] = useState('');

	useEffect(()=>{
		async function getDownloadLink(){
			if(post.hasFirebaseDownload){
					const downloadLink = await fb.getDownloadUrl(post.downloadLink);
					setLinkRef(downloadLink);
			} else {
					setLinkRef(post.downloadLink);
			}
		}
		getDownloadLink();
	})

	return (
		<div className="ci-description">
			<Copy copyArr={post.description}/>
			{
				(post.hasDownload ) ? (
					<>
						<a id={downloadid} target="_blank" href={linkRef} rel="noreferrer">
							<b> Download it here </b>
						</a>
						(right click, save link as).
					</>
				) : null
			}
		</div>
	);
}

function Picture({post}){
	return (
		<picture className="ci-picture" >
			<source className="img-sm ci-picture-source max-100 no-min-width no-min-height" srcSet={post.pictureSources[0]} media="(max-width: 480px)" />
			<source className="img-lg ci-picture-source max-100 no-min-width no-min-height" srcSet={post.pictureSources[1]} media="(min-width: 481px)" />
			<img className="img-dft ci-picture-source ci-picture-source-img max-100 no-min-width no-min-height" src={post.pictureSources[1]} alt={post.imgAlt} />
		</picture>
	)
}



function Post({post}){
	const id = (post.hasDownload) ? removeSpecialCharacters(post.trackInformation[1]) : null;
	return (
		<article className="content-item nomaxheight">
			<header className="ci-header">
				<h1 className="ci-heading">{post.heading}</h1>
			</header>
			<figure className="ci-media">
				{(post.hasImages) ? (
					<Picture post={post} />
				) : null}

				{(post.hasYoutube) ? (
					<YouTube description={post.description} embedLink={post.embedLink} />
				) : null}
				<figcaption className="ci-media-label">{post.caption}</figcaption>
			</figure>


			{(post.hasEvent) ? (
				<Event date={post.date} location={post.location} ticketLink={post.ticketLink} />
			):null}

			{(post.hasDescription) ? (
				<Description post={post} id={id} />
			):null}

			{(post.hasAudio) ? (
				<SoundPlayer
					trackInformation={post.trackInformation}
					audioContentUrl={post.audioContent}
					hasSoundcloud={post.hasSoundcloud}
				/>
			): null }
			{(post.hasTracklist) ? (
				<Tracklist tracklist={post.tracklist} />
			):null}
		</article>
	);
}



export const Main = ()=>{
	return (
		<>
			{
				db.posts.map((post, idx)=>{
					return (<Post post={post} key={idx} />)
				})
			}
		</>
	)
}
